interface WithCacheOptions {
  ttlMs?: number;
}

export interface WithCacheState<T> {
  cache: T | null;
  cacheTimestamp: number | null;
}

export function withCache<T>(fetchFn: () => Promise<T>, state: WithCacheState<T>, options?: WithCacheOptions): () => Promise<T> {
  return async (): Promise<T> => {
    const { ttlMs } = options || {};
    const cachedValue = state.cache;
    const timestamp = state.cacheTimestamp;
    const now = Date.now();

    if (cachedValue !== null && (!ttlMs || !timestamp || now - timestamp < ttlMs)) {
      return cachedValue as T;
    }

    const result = await fetchFn();

    /* eslint-disable no-param-reassign */
    state.cache = result;
    state.cacheTimestamp = Date.now();
    /* eslint-enable no-param-reassign */

    return result;
  };
}

export function resetCache<T>(state: WithCacheState<T>, defaultState?: WithCacheState<T>) {
  /* eslint-disable no-param-reassign */
  state.cache = defaultState?.cache ?? null;
  state.cacheTimestamp = defaultState?.cacheTimestamp ?? null;
  /* eslint-enable no-param-reassign */
}
