import type { AppState } from '@capacitor/app';
import { differenceInMilliseconds } from 'date-fns';
import { computed, ref } from 'vue';

import fulfillWithTimeLimit from '@exchange/helpers/fulfill-with-time-limit';
import { secureStorageGet, secureStorageSet, secureStorageReset } from '@exchange/helpers/secure-storage-helpers';
import { nativeAppUpdateChecker } from '@exchange/libs/native-app-update/service/src';
import {
  capIsNativePlatform,
  capApp,
  capGetPlatform,
  capNativeBiometric as NativeBiometric,
  type CapNativeBiometricAvailableResult as AvailableResult,
  capNativeBiometricBiometryType as BiometryType,
  type CapNativeBiometricBiometricOptions as BiometricOptions,
} from '@exchange/libs/utils/capacitor/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';
import { sleepService } from '@exchange/libs/utils/sleep/src';
import { BunSpotWebSocketManager } from '@exchange/libs/utils/wss/src';
import { checkIsCurrentBiometricRoute, getFullPath, getSearchParam } from '@exchange/routing';
import router from '@exchange/routing/router';

// TODO fix it
// eslint-disable-next-line import/no-cycle
import { authService } from './auth.service';

const wentToInactiveAtKey = 'bp_pro_went_inactive';

export enum VerifyIdentityError {
  'unavailable_or_error' = 'Biometrics error or unavailable',
  authenticationFailed = 'authenticationFailed',
  appCancel = 'appCancel',
  invalidContext = 'invalidContext',
  notInteractive = 'notInteractive',
  passcodeNotSet = 'passcodeNotSet',
  systemCancel = 'systemCancel', // happens when app with opened bio prompt is going out of focus
  userCancel = 'userCancel',
  userFallback = 'userFallback',
}

export const biometricErrors =
  capGetPlatform() === 'ios'
    ? {
        0: VerifyIdentityError.unavailable_or_error,
        10: VerifyIdentityError.authenticationFailed,
        11: VerifyIdentityError.appCancel,
        12: VerifyIdentityError.invalidContext,
        13: VerifyIdentityError.notInteractive,
        14: VerifyIdentityError.passcodeNotSet,
        15: VerifyIdentityError.systemCancel,
        16: VerifyIdentityError.userCancel,
        17: VerifyIdentityError.userFallback,
      }
    : {
        0: VerifyIdentityError.userCancel,
        10: VerifyIdentityError.authenticationFailed,
      };

class PlatformAuthenticator {
  public platform = capGetPlatform();

  public biometricType = ref(BiometryType.NONE);

  public biometricEnrolled = ref(false);

  public deviceCredEnrolled = ref(false);

  public deviceCredSet = computed(() => this.biometricEnrolled.value || this.deviceCredEnrolled.value);

  constructor() {
    if (capIsNativePlatform()) {
      this.checkIsBiometricAvailable();
      nativeAppUpdateChecker.checkAppUpdate();
    }
  }

  public wentToInactiveAt = {
    /**
     * the value holds the timestamp when the app was put in background;
     * when the app is put back in focus - the value is set to zero;
     * when user starts to login - the value is changed zero value that is used in "was the app killed" logic
     */
    get: () =>
      secureStorageGet<number | undefined>(
        wentToInactiveAtKey,
        (info) => Number(JSON.parse(info.value)),
        () => undefined,
      ).then((e) => (e !== undefined ? Number(e) : undefined)),
    set: (value: number) => secureStorageSet(wentToInactiveAtKey, JSON.stringify(value)),
    remove: () => secureStorageReset(wentToInactiveAtKey),
  };

  public async resetEverything() {
    await this.wentToInactiveAt.set(0);
  }

  public checkIsBiometricAvailable = () =>
    NativeBiometric.isAvailable({ useFallback: true })
      .then((result: AvailableResult) => {
        this.biometricEnrolled.value = result.isAvailable;
        this.biometricType.value = result.biometryType;
        this.deviceCredEnrolled.value = result.isAvailable;
      })
      .catch((error) => {
        logger.error("Couldn't check availability", error);
      });

  public biometricVerifyIdentity = (options: BiometricOptions) =>
    NativeBiometric.verifyIdentity({
      ...options,
      useFallback: true,
      maxAttempts: 5,
    });

  public shouldAppBeLocked = (trackingId?: string) => this.shouldBeLockedDueToInactiveTime(trackingId);

  private lockTimeReached = (serverTime: number, timeWentToInactive: number) => {
    const diff = differenceInMilliseconds(serverTime, timeWentToInactive) - CONSTANTS.SET_APP_ASLEEP_TIMEOUT;

    return diff > 0;
  };

  private shouldBeLockedDueToInactiveTime = async (trackingId?: string) => {
    const [timeWentToInactive, serverTime] = await Promise.all([this.wentToInactiveAt.get(), serverClientTimeService.getReputedlyServerTime()]);
    const shouldBeLocked = timeWentToInactive === undefined || (timeWentToInactive !== 0 && this.lockTimeReached(serverTime, timeWentToInactive));

    nonProdConsoleInfo(
      trackingId,
      'Should be locked due to inactive time: ',
      'wentToInactiveAt: ',
      timeWentToInactive,
      'serverTime: ',
      serverTime,
      'shouldBeLocked',
      shouldBeLocked,
    );

    return shouldBeLocked;
  };

  public wasProbablyRecentlyKilled = async () => {
    const [timeWentToInactive, serverTime] = await Promise.all([this.wentToInactiveAt.get(), serverClientTimeService.getReputedlyServerTime()]);
    const probablyKilledOrOrJustLoggedIn = timeWentToInactive === undefined || !this.lockTimeReached(serverTime, timeWentToInactive);

    nonProdConsoleInfo(
      'Fuzzy "recently killed" detection: ',
      'wentToInactiveAt: ',
      timeWentToInactive,
      'serverTime: ',
      serverTime,
      'probablyKilledOrOrJustLoggedIn',
      probablyKilledOrOrJustLoggedIn,
    );

    return probablyKilledOrOrJustLoggedIn;
  };

  public proceedWithBiometricFlow = () => {
    let redirectTo = getFullPath();
    const currentIsBiometric = checkIsCurrentBiometricRoute();

    if (currentIsBiometric) {
      redirectTo = getSearchParam('redirect') || '/';
    }

    nonProdConsoleInfo('Proceeding with biometric flow', 'currentIsBiometric', currentIsBiometric, 'redirectTo', redirectTo);

    return router.push({ name: 'biometric.unlock', query: { redirect: redirectTo } });
  };

  public async continueSession() {
    if (checkIsCurrentBiometricRoute()) {
      nonProdConsoleInfo('Continuing session: already on biometric route => resetting wentToInactiveAt and returning from continue');

      return Promise.resolve();
    }

    if (authService.isLoginProcess.value) {
      await fulfillWithTimeLimit(authService.waitForLoginProcessDone(), 5_000, undefined);

      nonProdConsoleInfo('Continuing session: ', 'is login process ongoing', authService.isLoginProcess.value);
    }

    const [shouldAppBeLocked, isRefreshTokeExpired] = await Promise.all([this.shouldAppBeLocked('continueSession'), authService.isRefreshTokeExpired()]);

    nonProdConsoleInfo(
      'Continuing session: ',
      'hasBeenAuthenticated',
      authService.hasBeenAuthenticated,
      'isAuthenticated',
      authService.isAuthenticated,
      'isAuthenticatedHolder',
      authService.isAuthenticatedHolder,
      'isRefreshTokeExpired',
      isRefreshTokeExpired,
      'shouldAppBeLocked: ',
      shouldAppBeLocked,
    );

    if (shouldAppBeLocked) {
      if (!isRefreshTokeExpired) {
        return this.proceedWithBiometricFlow();
      }

      nonProdConsoleInfo('Should be locked by time, but refresh token expired or user was logged out => resetting auth');

      await authService.resetAuth({ isUserInteraction: false, unsubscribeAccountHistory: false });
    }

    await this.wentToInactiveAt.set(0);
    await this.connectToWebSockets('continueSession');

    return Promise.resolve();
  }

  public connectToWebSockets = (funNameForLogging: string) =>
    BunSpotWebSocketManager.connect(funNameForLogging)?.catch((e) => {
      logger.error(`Failed to connect to bun WSS:`, e, new Date());
    });

  public async uponReturnFromBiometricFlow() {
    try {
      await this.wentToInactiveAt.set(0);

      nonProdConsoleInfo('Upon returning from biometric flow: isAuthenticated:', authService.isAuthenticated);

      await authService.checkAndTryToUpdateAuthUponReturningToFromBeingInactive();
    } catch (e) {
      logger.error('Failure upon return to the app:', e, new Date());
      await authService.resetAuth({ isUserInteraction: false, unsubscribeAccountHistory: false });
    }
  }
}

const appPlatformAuthenticator = new PlatformAuthenticator();

export const onAppStateActive = () => {
  nonProdConsoleInfo('=>> onAppStateActive');
  sleepService.updateGlobalSleepDetection({ visible: true });

  return Promise.all([appPlatformAuthenticator.checkIsBiometricAvailable(), appPlatformAuthenticator.continueSession(), nativeAppUpdateChecker.checkAppUpdate()]);
};

export const onAppStateInactive = () => {
  nonProdConsoleInfo('=>> onAppStateInactive');
  sleepService.updateGlobalSleepDetection({ visible: false });

  return BunSpotWebSocketManager.disconnect('appStateInactive');
};

const onAppStateChange = (state: AppState) => {
  if (!capIsNativePlatform()) {
    return;
  }

  if (state.isActive) {
    onAppStateActive();
  } else {
    if (!checkIsCurrentBiometricRoute()) {
      serverClientTimeService.getReputedlyServerTime().then(appPlatformAuthenticator.wentToInactiveAt.set);
    }

    onAppStateInactive();
  }
};

const onBackButton = async () => {
  if (!checkIsCurrentBiometricRoute()) {
    window.history.back();
  }
};

export const subscribeToCapAppEvents = () => {
  // https://capacitorjs.com/docs/apis/app#addlistenerappstatechange
  capApp.addListener('appStateChange', onAppStateChange);
  // https://capacitorjs.com/docs/apis/app#addlistenerbackbutton
  capApp.addListener('backButton', onBackButton);
};

export default appPlatformAuthenticator;
