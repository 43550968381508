import { shallowRef } from 'vue';

import RetailAmlModal from '@exchange/libs/account/ui/src/libs/RetailAmlModal.vue';
import { modalService } from '@exchange/libs/modals/src';
import WebRest from '@exchange/libs/rest-api/web-api';
import type { VerificationInformation } from '@exchange/libs/rest-api/web-api/customer/verification-information-resource';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

import type { UserCheckHandlerWithData } from './user-check';

const showRetailAmlModal = async (nativeCallback: () => void) => {
  const mKey = modalService.show(
    shallowRef(RetailAmlModal),
    {},
    {
      'go-to-form': () => {
        const amlUrl = `${process.env.VUE_APP_ACCOUNTS_URL}${CONSTANTS.AML_PATHNAME}`;

        if (capIsNativePlatform()) {
          handleNativeGoTo(amlUrl, nativeCallback, () => {
            toastManagerInstance.addToast({
              content: SimpleToast,
              props: {
                variant: 'failed',
                title: 'fundamentals.toasts.failed',
                message: 'fundamentals.error.text',
              },
            });
          });
          modalService.hide(mKey);
        } else {
          window.location.href = amlUrl;
        }
      },
    },
    { canClose: false, variant: 'dark' },
  );
};

const handleMissingRetailAml: UserCheckHandlerWithData<[boolean, () => void], VerificationInformation | undefined> = async (isRetail, nativeCallback) => {
  if (!isRetail) {
    return {
      actionRequired: false,
      data: undefined,
    };
  }

  const verificationInfo = await WebRest.Customer.VerificationInfo.get();

  if (verificationInfo.aml.date) {
    return {
      actionRequired: false,
      data: verificationInfo,
    };
  }

  return {
    actionRequired: true,
    action: () => showRetailAmlModal(nativeCallback),
    data: verificationInfo,
  };
};

export default handleMissingRetailAml;
