/* eslint-disable camelcase */
import { map } from 'lodash/fp';

import type { MarketModel } from '@exchange/libs/market/service/src';
// eslint-disable-next-line max-len
import type { ResolutionString } from '@exchange-public/charting_library/charting_library.d'; // please be aware https://github.com/tradingview/charting_library/issues/5187#issuecomment-667097792

export const chartIntervals = {
  min1: {
    value: '1',
    label: '1 Minute',
  },
  min5: {
    value: '5',
    label: '5 Minutes',
  },
  min15: {
    value: '15',
    label: '15 Minutes',
  },
  min30: {
    value: '30',
    label: '30 Minutes',
  },
  min60: {
    value: '60',
    label: '1 Hour',
  },
  min240: {
    value: '240',
    label: '4 Hours',
  },
  d: {
    value: '1D',
    label: '1 Day',
  },
  w: {
    value: '1W',
    label: '1 Week',
  },
  m: {
    value: '1M',
    label: '1 Month',
  },
} as const;
const getChartIntervalsValues = () => map((e) => e.value as ResolutionString, chartIntervals);

export const datafeedConfiguration = {
  exchanges: [],
  symbols_types: [],
  supported_resolutions: getChartIntervalsValues(),
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: true,
};

export const tradingViewTimeZones: Dictionary<number> = {
  'Pacific/Honolulu': -10,
  'America/Juneau': -8,
  'America/Los_Angeles': -7,
  'America/Phoenix': -7,
  'America/Vancouver': -7,
  'US/Mountain': -7,
  'America/El_Salvador': -6,
  'America/Bogota': -5,
  'America/Chicago': -5,
  'America/Lima': -5,
  'America/Mexico_City': -5,
  'America/Caracas': -4,
  'America/New_York': -4,
  'America/Toronto': -4,
  'America/Argentina/Buenos_Aires': -3,
  'America/Santiago': -3,
  'America/Sao_Paulo': -3,
  'Etc/UTC': 0,
  'Atlantic/Reykjavik': 0,
  'Africa/Lagos': 1,
  'Europe/London': 1,
  'Africa/Cairo': 2,
  'Africa/Johannesburg': 2,
  'Europe/Belgrade': 2,
  'Europe/Berlin': 2,
  'Europe/Copenhagen': 2,
  'Europe/Luxembourg': 2,
  'Europe/Madrid': 2,
  'Europe/Oslo': 2,
  'Europe/Paris': 2,
  'Europe/Rome': 2,
  'Europe/Stockholm': 2,
  'Europe/Warsaw': 2,
  'Europe/Zurich': 2,
  'Asia/Bahrain': 3,
  'Asia/Jerusalem': 3,
  'Asia/Kuwait': 3,
  'Asia/Qatar': 3,
  'Asia/Riyadh': 3,
  'Europe/Athens': 3,
  'Europe/Helsinki': 3,
  'Europe/Istanbul': 3,
  'Europe/Moscow': 3,
  'Europe/Riga': 3,
  'Europe/Tallinn': 3,
  'Europe/Vilnius': 3,
  'Asia/Kolkata': 3.5,
  'Asia/Dubai': 4,
  'Asia/Muscat': 4,
  'Asia/Tehran': 4.5,
  'Asia/Ashkhabad': 5,
  'Asia/Kathmandu': 5.75,
  'Asia/Almaty': 6,
  'Asia/Bangkok': 7,
  'Asia/Ho_Chi_Minh': 7,
  'Asia/Jakarta': 7,
  'Asia/Chongqing': 8,
  'Asia/Hong_Kong': 8,
  'Asia/Shanghai': 8,
  'Asia/Singapore': 8,
  'Asia/Taipei': 8,
  'Australia/Perth': 8,
  'Asia/Seoul': 9,
  'Asia/Tokyo': 9,
  'Australia/Adelaide': 9.5,
  'Australia/ACT': 10,
  'Australia/Brisbane': 10,
  'Australia/Sydney': 10,
  'Pacific/Norfolk': 11,
  'Pacific/Auckland': 12,
  'Pacific/Chatham': 12.75,
  'Pacific/Fakaofo': 13,
};

export type DataStatus = 'streaming' | 'endofday' | 'delayed_streaming';

export default class SymbolInfo implements TradingView.LibrarySymbolInfo {
  /** https://github.com/tradingview/charting_library/wiki/Symbology#symbolinfo-structure */
  public name!: string;

  public full_name!: string;

  public format: TradingView.SeriesFormat = 'price';

  public ticker!: string;

  public description!: string;

  public type = 'crypto';

  public session = '24x7';

  public exchange = ' ';

  public listed_exchange = 'One Trading';

  public timezone: TradingView.Timezone = 'Etc/UTC';

  public minmov = 1;

  public pricescale!: number;

  public minmove2 = 0;

  public fractoral = false;

  public has_intraday = true;

  public supported_resolutions: Array<ResolutionString> = getChartIntervalsValues();

  public intraday_multipliers = ['1', '5', '15', '30', '60', '240'];

  public has_seconds = false;

  public seconds_multipliers = [];

  public has_daily = true;

  public has_weekly_and_monthly = true;

  // eslint-disable-next-line max-len
  public has_empty_bars = false; // The boolean value showing whether the library should generate empty bars in the session when there is no data from the data feed for this particular time.

  public volume_precision!: number;

  public data_status: DataStatus = 'streaming';

  public expired = false;

  public expiration_date?: number;

  public sector = '';

  public industry = '';

  public currency_code!: string;

  constructor(market: MarketModel) {
    // https://github.com/tradingview/charting_library/wiki/Symbology
    const precision = Number(market.marketPrecision);

    this.currency_code = market.base.code;
    this.name = market.id.replace('_', '/');
    this.full_name = this.name;
    this.description = market.name;
    this.pricescale = 10 ** precision;
    this.ticker = market.id;
    this.volume_precision = precision;
  }
}
