import { shallowRef } from 'vue';

import CapitalGainsTaxAcknowledgement from '@exchange/libs/account/ui/src/libs/CapitalGainsTaxAcknowledgement.vue';
import { modalService } from '@exchange/libs/modals/src';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import type { UserCheckHandler } from './user-check';

const userTaxAcknowledgement = {
  questionnaireKey: 'cgt-acknowledgement',
  get: async () => {
    try {
      const data = await PublicRest.Customer.Questionnaire.get(userTaxAcknowledgement.questionnaireKey);

      return data;
    } catch (e) {
      if ((e as { status: number }).status === 404) {
        return undefined;
      }

      throw e;
    }
  },
  set: async () => {
    const payload = {
      answers: [
        {
          questionId: 1,
          answer: new Date().toISOString(),
        },
      ],
    };

    await PublicRest.Customer.Questionnaire.set(userTaxAcknowledgement.questionnaireKey, payload);
  },
};

const showTaxAcknowledgementModal = (pid: string) => {
  const mKey = modalService.show(
    shallowRef(CapitalGainsTaxAcknowledgement),
    {
      accept: async () => {
        try {
          await userTaxAcknowledgement.set();

          modalService.hide(mKey);
        } catch (e) {
          logger.error(`Saving tax acknowledgement for user ${pid} failed`, e);
          toastManagerInstance.addToast({
            content: SimpleToast,
            props: {
              variant: 'failed',
              title: 'fundamentals.toasts.failed',
              message: 'fundamentals.error.text',
            },
          });
        }
      },
    },
    {},
    { canClose: false, centered: true, variant: 'dark' },
  );
};

const handleTaxAcknowledgement: UserCheckHandler<[string]> = async (pid) => {
  const data = await userTaxAcknowledgement.get().catch((e) => {
    logger.error(`Obtaining tax acknowledgement for user ${pid} failed`, e);
    return undefined;
  });

  if (data) {
    return {
      actionRequired: false,
    };
  }

  return {
    actionRequired: true,
    action: () => showTaxAcknowledgementModal(pid),
  };
};

export default handleTaxAcknowledgement;
