import { computed, reactive, ref } from 'vue';

import WebRest from '@exchange/libs/rest-api/web-api';
import type { MarketTypeTradingEligibility } from '@exchange/libs/rest-api/web-api/user/market-type-trading-eligibility-resource';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

type MarketTypeTradingEligibilityServiceState = {
  marketTypeTradingEligibility: MarketTypeTradingEligibility | null | undefined;
};

const getDefaultState = (): MarketTypeTradingEligibilityServiceState => ({
  marketTypeTradingEligibility: null,
});

class MarketTypeTradingEligibilityService {
  private readonly state = reactive<MarketTypeTradingEligibilityServiceState>(getDefaultState());

  private isFuturesEnabled = computed(() => launchdarkly.flags['futures-enabled'].value);

  public marketTypeTradingEligibility = computed(() => {
    if (!this.isFuturesEnabled.value) {
      return {
        ...this.state.marketTypeTradingEligibility,
        futures: false,
      };
    }

    return this.state.marketTypeTradingEligibility;
  });

  public canTradeFutures = computed(() => !!this.marketTypeTradingEligibility.value?.futures);

  public canTradeSpot = computed(() => !!this.marketTypeTradingEligibility.value?.spot);

  public isFetchedMarketTypeTradingEligibility = computed(() => this.state.marketTypeTradingEligibility !== getDefaultState().marketTypeTradingEligibility);

  public isLoadingMarketTypeTradingEligibility = ref(false);

  public async getMarketTypeTradingEligibility(refetch = false): Promise<MarketTypeTradingEligibility | undefined> {
    if (this.state.marketTypeTradingEligibility && !refetch) {
      return this.state.marketTypeTradingEligibility;
    }

    if (this.isLoadingMarketTypeTradingEligibility.value) {
      return undefined;
    }

    this.isLoadingMarketTypeTradingEligibility.value = true;

    try {
      const data = await WebRest.User().MarketTypeTradingEligibility.get();

      this.state.marketTypeTradingEligibility = data;

      return data;
    } catch (error) {
      logger.error('Fetching market type trading eligibility failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      return await this.getMarketTypeTradingEligibility();
    } finally {
      this.isLoadingMarketTypeTradingEligibility.value = false;
    }
  }

  public async refetchMarketTypeTradingEligibility() {
    return this.getMarketTypeTradingEligibility(true);
  }

  public reset() {
    Object.assign(this.state, getDefaultState());
  }
}

const marketTypeTradingEligibilityService = new MarketTypeTradingEligibilityService();

export default marketTypeTradingEligibilityService;
