import { computed, shallowRef, watch } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import marketTypeTradingEligibilityService from '@exchange/libs/account/service/src/lib/market-type-trading-eligibility.service';
import { authService } from '@exchange/libs/auth/service/src';
import { futuresService } from '@exchange/libs/futures/service/src';
import FuturesAppropriatenessManualReviewModal from '@exchange/libs/futures/ui/src/lib/components/FuturesAppropriatenessManualReviewModal.vue';
import FuturesAppropriatenessRetakeModal from '@exchange/libs/futures/ui/src/lib/components/FuturesAppropriatenessRetakeModal.vue';
import { modalService } from '@exchange/libs/modals/src';
import {
  type FuturesAppropriatenessAssessment,
  type FuturesAppropriatenessResult,
  FuturesAppropriatenessResultStatus,
} from '@exchange/libs/rest-api/web-api/customer/futures-appropriateness-resource';
import { settingsService } from '@exchange/libs/settings/service/src';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { checkIsBiometricRoute, router } from '@exchange/routing';

const userHasBeenAuthenticated = computed(() => authService.hasBeenAuthenticated);
const { isFetchedAssessment: isFetchedFuturesAppropriatenessAssessment, assessment: futuresAppropriatenessAssessment } = futuresService;

enum FuturesAssessmentAcknowledgementType {
  RETAKE_ELIGIBILITY_RESULT = 'retakeEligibilityResult',
  MANUAL_REVIEW_RESULT = 'manualReviewResult',
}

const getFuturesAssessmentAcknowledgementKey = (assessmentDate = '') => assessmentDate.replace(/\./g, '');

export const hasAcknowledgedFuturesAssessmentNotification = (acknowledgmentType: FuturesAssessmentAcknowledgementType, assessmentDate: string) => {
  const acknowledgedDates = settingsService.settings.userSettings?.futuresSettings?.[acknowledgmentType] ?? {};

  return getFuturesAssessmentAcknowledgementKey(assessmentDate) in acknowledgedDates;
};

const isFuturesAssessmentRetakeNotificationApplicable = (assessmentResult: FuturesAppropriatenessResult) =>
  assessmentResult?.status === FuturesAppropriatenessResultStatus.FAILED && assessmentResult.canRetake && assessmentResult.hadRetakeCooldown;

const isFuturesAssessmentManualReviewNotificationApplicable = (assessmentResult: FuturesAppropriatenessResult) =>
  (assessmentResult?.status === FuturesAppropriatenessResultStatus.PASSED || assessmentResult?.status === FuturesAppropriatenessResultStatus.FAILED) &&
  assessmentResult.manualReview;

export const hasShownFuturesAssessmentManualReviewNotification = (futuresAssessment: FuturesAppropriatenessAssessment) => {
  if (!futuresAssessment.result) {
    return false;
  }

  return (
    isFuturesAssessmentManualReviewNotificationApplicable(futuresAssessment.result) &&
    !hasAcknowledgedFuturesAssessmentNotification(FuturesAssessmentAcknowledgementType.MANUAL_REVIEW_RESULT, futuresAssessment.date)
  );
};

export const useGlobalNotificationModals = (route: RouteLocationNormalized) => {
  const shouldShowModals = computed(() => !checkIsBiometricRoute(route) && userHasBeenAuthenticated.value);

  const handleFuturesAssessmentRetakeReminder = async () => {
    const futuresAppropriatenessAssessmentRetakeUrl = `${process.env.VUE_APP_ACCOUNTS_URL}${CONSTANTS.FUTURES_PATHNAME}/appropriateness-test/retake`;

    const handleModalDismissal = () => {
      try {
        const date = futuresAppropriatenessAssessment.value?.date;

        settingsService.setSettings({
          path: `futuresSettings.${FuturesAssessmentAcknowledgementType.RETAKE_ELIGIBILITY_RESULT}.${getFuturesAssessmentAcknowledgementKey(date)}`,
          value: true,
        });
      } catch (error) {
        logger.error('Failed to set futures appropriateness retake eligibility status setting');
      }
    };

    const mKey = modalService.show(
      shallowRef(FuturesAppropriatenessRetakeModal),
      {
        canDismiss: true,
      },
      {
        dismiss: () => {
          modalService.hide(mKey);
          handleModalDismissal();
        },
        retake: () => {
          if (capIsNativePlatform()) {
            handleNativeGoTo(
              futuresAppropriatenessAssessmentRetakeUrl,
              async () => {
                await Promise.all([futuresService.refetchFuturesAppropriatenessAssessment(), marketTypeTradingEligibilityService.refetchMarketTypeTradingEligibility()]);
              },
              () => {
                toastManagerInstance.addToast({
                  content: SimpleToast,
                  props: {
                    variant: 'failed',
                    title: 'fundamentals.toasts.failed',
                    message: 'fundamentals.error.text',
                  },
                });
              },
            );
            modalService.hide(mKey);
            handleModalDismissal();
          } else {
            window.location.href = futuresAppropriatenessAssessmentRetakeUrl;
          }
        },
        'modal-close': handleModalDismissal,
        'modals-container-on-outside-click': handleModalDismissal,
      },
    );
  };

  const handleFuturesAssessmentManualReviewResult = async () => {
    const handleModalDismissal = () => {
      try {
        const date = futuresAppropriatenessAssessment.value?.date;

        settingsService.setSettings({
          path: `futuresSettings.${FuturesAssessmentAcknowledgementType.MANUAL_REVIEW_RESULT}.${getFuturesAssessmentAcknowledgementKey(date)}`,
          value: true,
        });
      } catch (error) {
        logger.error('Failed to set futures appropriateness manual review status setting');
      }
    };

    const mKey = modalService.show(
      shallowRef(FuturesAppropriatenessManualReviewModal),
      {
        result: futuresAppropriatenessAssessment.value?.result,
      },
      {
        close: () => {
          modalService.hide(mKey);
          handleModalDismissal();
        },
        deposit: () => {
          modalService.hide(mKey);
          handleModalDismissal();
          router.push({ hash: '#Deposit' });
        },
        'modal-close': handleModalDismissal,
        'modals-container-on-outside-click': handleModalDismissal,
      },
    );
  };

  watch(
    shouldShowModals,
    (newShouldShowModals) => {
      if (!newShouldShowModals) {
        return;
      }

      futuresService.getFuturesAppropriatenessAssessment();
    },
    {
      immediate: true,
    },
  );

  watch(
    futuresAppropriatenessAssessment,
    async (newAssessment) => {
      if (!isFetchedFuturesAppropriatenessAssessment.value || !newAssessment?.result) {
        return;
      }

      if (
        isFuturesAssessmentRetakeNotificationApplicable(newAssessment.result) &&
        !hasAcknowledgedFuturesAssessmentNotification(FuturesAssessmentAcknowledgementType.RETAKE_ELIGIBILITY_RESULT, newAssessment.date)
      ) {
        await handleFuturesAssessmentRetakeReminder();
        return;
      }

      if (
        isFuturesAssessmentManualReviewNotificationApplicable(newAssessment.result) &&
        !hasAcknowledgedFuturesAssessmentNotification(FuturesAssessmentAcknowledgementType.MANUAL_REVIEW_RESULT, newAssessment.date)
      ) {
        await handleFuturesAssessmentManualReviewResult();
      }
    },
    {
      immediate: true,
    },
  );
};

export default useGlobalNotificationModals;
