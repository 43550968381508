<script lang="ts" setup>
import { type OtSelectOption } from '@1trading/design-system';
import { useVuelidate } from '@vuelidate/core';
import { required as requiredValidator } from '@vuelidate/validators';
import { computed, reactive, type PropType, useAttrs } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: true },
  value: { type: Array as PropType<Array<OtSelectOption>>, required: true },
});

const $attrs = useAttrs();
const { t } = useI18n({ useScope: 'global' });

const dummyCheck = () => true;
const validationRules = {
  internalVModel: { required: props.required ? requiredValidator : dummyCheck },
};

const v = useVuelidate(validationRules, reactive({ internalVModel: props.value }));
const validationErrorMessages = computed(() => ({
  internalVModel: {
    required: t('fundamentals.multiSelect.required'),
  },
}));

const label = computed(() => (typeof $attrs.label === 'string' ? $attrs.label : 'label'));
const selectedValues = computed(() => v.value.internalVModel.$model.map((option) => option[label.value]));

const isOptionSelected = (option: OtSelectOption) => selectedValues.value.includes(option[label.value]);
</script>

<template>
  <ot-input-error-wrapper
    :error="v.internalVModel"
    :error-messages="validationErrorMessages.internalVModel"
    :disabled="disabled"
  >
    <ot-select
      v-bind="$attrs"
      v-model:value="v.internalVModel.$model"
      class="x-select-multi"
      :disabled="disabled"
      :multiple="true"
    >
      <template #option="option">
        <ot-list-item
          :title="option[label]"
          :variant="$attrs.variant === 'light-outlined' ? 'light' : 'default'"
          :active="isOptionSelected(option)"
        >
          <template #label-additional-content>
            <ot-icon
              v-if="isOptionSelected(option)"
              class="x-select-multi__dropdown-option-icon"
              name="checkmark"
              size="small"
            />
          </template>
        </ot-list-item>
      </template>
    </ot-select>
  </ot-input-error-wrapper>
</template>

<style lang="scss" scoped>
.x-select-multi {
  &__dropdown-option-icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-left: auto;
    color: var(--vs-selected-color);
  }
}
</style>
