import isEmpty from 'lodash/isEmpty';
import { computed, reactive, ref, watch } from 'vue';

import { balanceService } from '@exchange/libs/balances/service/src';
import { eotcService } from '@exchange/libs/eotc/service/src';
import { featureRestrictionsService } from '@exchange/libs/feature-restrictions/service/src';
import { feesService } from '@exchange/libs/fees/service/src';
import { accountRatioService, futuresService } from '@exchange/libs/futures/service/src';
import { ordersService } from '@exchange/libs/order/my-orders/service/src';
import { OrderSnapshotType } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import CryptoInfoRest from '@exchange/libs/rest-api/crypto-info-api';
import PersonalRest from '@exchange/libs/rest-api/personal-api';
import WebRest from '@exchange/libs/rest-api/web-api';
import type { VerificationInformation } from '@exchange/libs/rest-api/web-api/customer/verification-information-resource';
import { settingsService } from '@exchange/libs/settings/service/src';
import { subaccountsService } from '@exchange/libs/trading-accounts/service/src';
import type { SubaccountType } from '@exchange/libs/trading-accounts/service/src/lib/models/subaccount-model';
import { fundsService, type HolderDetails } from '@exchange/libs/transactions/funds/service/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { sleepService } from '@exchange/libs/utils/sleep/src';
import { WSIncomingEventTypes } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import accountHistoryChannel, { type AccountChannelGetTokenListener } from './AccountHistory.channel';
import handleForbiddenJurisdiction from './handle-forbidden-jurisdiction';
import handleMissingRetailAml from './handle-missing-retail-aml';
import handleTaxAcknowledgement from './handle-tax-acknowledgement';
import handleUKCustomer from './handle-uk-customer';
import marketTypeTradingEligibilityService from './market-type-trading-eligibility.service';
import type { UserCheckHandlerResponseActionRequired } from './user-check';
import UserModel, { VerificationTypes, AccountStatuses } from './user-model';
import type { AccountUpdateMessage, OrderSnapshot, BalancesSnapshot, WSSTradingUpdate, WSSFundingUpdate, ActivePositionsSnapshot, WSMarginUpdate } from './wss-account-messages';
import { AccountUpdateActivity } from './wss-account-messages';

type AccountStateData = {
  accountId: string | undefined;
  accountUIId: string | undefined;
  accountIdHolder: string | undefined;
  accountUser: UserModel | null;
  accountDetails: HolderDetails | undefined;
  accountUserChecksFetched: boolean;
  accountUserChecksActionRequired: boolean;
  adopter: boolean;
  retailVerificationInfo: VerificationInformation | undefined;
  mainAccountType: SubaccountType | undefined;
  subaccountType: SubaccountType | undefined;
};

type AccountStateUnsubscribeMethods = {
  unsubscribeAccountHistory?: () => Promise<void>;
  unsubscribeTrading?: () => Promise<void>;
};

type AccountState = AccountStateData & AccountStateUnsubscribeMethods;

const getDefaultStateData = (): AccountStateData => ({
  accountId: undefined,
  accountUIId: undefined,
  accountIdHolder: undefined,
  accountUser: null,
  accountDetails: undefined,
  accountUserChecksFetched: false,
  accountUserChecksActionRequired: false,
  adopter: false,
  retailVerificationInfo: undefined,
  mainAccountType: undefined,
  subaccountType: undefined,
});

const getDefaultStateUnsubscribeMethods = (): AccountStateUnsubscribeMethods => ({
  unsubscribeAccountHistory: undefined,
  unsubscribeTrading: undefined,
});

const getDefaultState = (): AccountState => ({
  ...getDefaultStateData(),
  ...getDefaultStateUnsubscribeMethods(),
});

class AccountService {
  private readonly logPrefix = 'AccountService:';

  private readonly state = reactive<AccountState>(getDefaultState());

  public accountUserChecksFetched = computed(() => this.state.accountUserChecksFetched);

  public accountUserChecksActionRequired = computed(() => this.state.accountUserChecksActionRequired);

  public userName = computed(() => this.state.accountUser?.fullName ?? this.state.accountDetails?.account_holder_name ?? '');

  public accountId = computed(() => this.state.accountId);

  public accountUIId = computed(() => this.state.accountUIId);

  public accountIdHolder = computed(() => this.state.accountIdHolder);

  public isMainAccount = computed(() => Boolean(this.state.accountId && this.isSubaccountMain(this.state.accountId)));

  public isInstantAccount = computed(() => this.state.accountUIId === eotcService.accountIdInstant);

  public userEmail = computed(() => this.state.accountUser?.email || '');

  public accountUser = computed(() => this.state.accountUser);

  public userIsLoaded = computed(() => !isEmpty(this.state.accountUser?.userData));

  public accountStatus = computed(() => this.state.accountUser?.userData.account_status);

  public accountVerificationDate = computed(() => this.state.accountUser?.userData.verification_date);

  public accountStatusApproved = computed(() => this.accountStatus.value === AccountStatuses.APPROVED);

  public retailAccountVerificationInfo = computed(() => this.state.retailVerificationInfo);

  public subaccountType = computed(() => this.state.subaccountType);

  public mainAccountType = computed(() => this.state.mainAccountType);

  public userIsBlocked = computed(() => featureRestrictionsService.isBlocked.value);

  public userIsVerified = computed(() => {
    const verified = this.state.accountUser?.userData?.verified;

    if (verified === undefined) {
      return false;
    }

    return [VerificationTypes.FULL, VerificationTypes.LIGHT].includes(verified);
  });

  public securityGet = {
    showVerify: ({ isLoaded, isVerified }: { isLoaded: boolean; isVerified: boolean }) => isLoaded && !isVerified,
  };

  public securityShow = {
    verify: computed(() =>
      this.securityGet.showVerify({
        isLoaded: this.userIsLoaded.value,
        isVerified: this.userIsVerified.value,
      }),
    ),
  };

  public showSecurityWarning = computed(() => this.securityShow.verify.value);

  public setAccountUIId = (id?: string) => {
    if (!id) {
      return;
    }

    this.state.accountUIId = id;
  };

  public isSubaccountMain = (accountId: string) => Boolean(accountId === this.state.accountIdHolder);

  private unsubscribeToAccountHistory = () =>
    (this.state.unsubscribeAccountHistory?.() || Promise.resolve()).catch((e) => {
      logger.error(`${this.logPrefix} unsubscribe from ACCOUNT_HISTORY failed`, e);
    });

  public async subscribeToAccountHistory(getAccessToken: AccountChannelGetTokenListener) {
    try {
      await this.unsubscribeToAccountHistory();

      await new Promise<void>((res, rej) => {
        this.state.unsubscribeAccountHistory = accountHistoryChannel.subscribe(
          {
            getAccessToken,
            onMessage: (event) => {
              switch (event.type) {
                case WSIncomingEventTypes.ACTIVE_ORDERS_SNAPSHOT:
                  ordersService.setSnapshot({ event: event as OrderSnapshot, type: OrderSnapshotType.ACTIVE });
                  break;
                case WSIncomingEventTypes.INACTIVE_ORDERS_SNAPSHOT:
                  ordersService.setSnapshot({ event: event as OrderSnapshot, type: OrderSnapshotType.INACTIVE });
                  break;
                case WSIncomingEventTypes.BALANCES_SNAPSHOT:
                  balanceService.setSnapshot(event as BalancesSnapshot);
                  break;
                case WSIncomingEventTypes.ACCOUNT_UPDATE: {
                  const { update } = event as AccountUpdateMessage;

                  switch (update.activity) {
                    case AccountUpdateActivity.TRADING:
                      ordersService.processTradingUpdateAndAdjustBalance(update as WSSTradingUpdate);
                      break;
                    case AccountUpdateActivity.FUNDING:
                      balanceService.processFundingUpdate(update as WSSFundingUpdate);
                      break;
                    default:
                      logger.error(`${this.logPrefix}Unsupported update activity ${update.activity}, ${update}`);
                      break;
                  }
                  break;
                }
                case WSIncomingEventTypes.MARGIN_UPDATE: {
                  accountRatioService.processMarginUpdate(event as WSMarginUpdate);
                  break;
                }
                case WSIncomingEventTypes.OPEN_POSITIONS_SNAPSHOT:
                  ordersService.setPerpsPositionsSnapshot({ event: event as ActivePositionsSnapshot });
                  break;
                default:
              }
            },
          },
          {
            success: () => res(),
            fail: (error) => rej(error),
          },
        );
      });
    } catch (error) {
      logger.warn(`${this.logPrefix} subscription failed; retrying later`, error);
      await this.unsubscribeToAccountHistory();
      await retryService.waitForNextRetryTick();
      await this.subscribeToAccountHistory(getAccessToken);
    }
  }

  public getAccountId = ({ useSubaccount = true } = {}): Promise<string> => {
    if (useSubaccount) {
      if (this.state.accountId) {
        return Promise.resolve(this.state.accountId);
      }

      return new Promise((resolve) => {
        const watchStopHandle = watch(
          () => this.state.accountId,
          // eslint-disable-next-line consistent-return
          (newValue) => {
            if (newValue) {
              watchStopHandle();
              return resolve(newValue);
            }
          },
        );
      });
    }

    if (this.state.accountIdHolder) {
      return Promise.resolve(this.state.accountIdHolder);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.accountIdHolder,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue);
          }
        },
      );
    });
  };

  private async setAccountTypes() {
    await subaccountsService.awaitSubaccountList();

    if (this.state.accountIdHolder) {
      const mainAccount = subaccountsService.getSubaccountById(this.state.accountIdHolder);
      if (mainAccount) this.state.mainAccountType = mainAccount.type;
    }

    if (this.state.accountId) {
      const subaccount = subaccountsService.getSubaccountById(this.state.accountId);
      if (subaccount) this.state.subaccountType = subaccount.type;
    }
  }

  private isFetchingUserHolder = ref(false);

  private isFetchingUserChecks = ref(false);

  public fetchHolderUser = async () => {
    const accountId = this.state.accountIdHolder;

    if (!accountId) {
      throw new Error('Cannot fetch user without account id');
    }

    if (this.isFetchingUserHolder.value) {
      return;
    }

    this.isFetchingUserHolder.value = true;

    try {
      const [data, accountDetails] = await Promise.all([WebRest.User(accountId).get(), fundsService.accountHolder.get()]);
      const user = new UserModel(data);

      this.state.accountUser = user;
      this.state.accountDetails = accountDetails;

      // Note that this is an async function but we're not awaiting it here as we don't want it to be blocking
      this.setAccountTypes();

      if (!this.state.accountUserChecksFetched) {
        await this.fetchUserChecks();
      }
    } catch (e) {
      const error = (e as { data: unknown | undefined; message: string }).data || (e as { data: unknown | undefined; message: string }).message;

      logger.error(`${this.logPrefix} Failed to fetch user:`, error);
      throw e;
    } finally {
      this.isFetchingUserHolder.value = false;
    }
  };

  public fetchUserChecks = async () => {
    const { accountIdHolder, accountUser, mainAccountType } = this.state;

    if (!accountIdHolder || !accountUser) {
      throw new Error('Cannot fetch user checks without account id or user');
    }

    if (this.isFetchingUserChecks.value) {
      return;
    }

    this.isFetchingUserChecks.value = true;

    try {
      const identity = await this.checkIdentity();

      /*
       * Note: The order here is important. We should also be mindful of what checks we're doing here as the fetchHolderUser
       * function (which calls this function) is called in several places (page load, deposits and withdraws to name a few)
       */
      const userChecks = await Promise.all([
        handleForbiddenJurisdiction({ email: accountUser.email, residency: identity.residency }),
        handleUKCustomer(identity, accountIdHolder, mainAccountType, this.fetchUserChecks),
        (async () => {
          const result = await handleMissingRetailAml(accountUser.isRetail, this.fetchUserChecks);
          if (result.data) this.state.retailVerificationInfo = result.data;

          return result;
        })(),
        handleTaxAcknowledgement(accountIdHolder),
      ]);

      const firstActionRequired = userChecks.find((check): check is UserCheckHandlerResponseActionRequired => check.actionRequired === true);

      if (firstActionRequired) {
        firstActionRequired.action();
        this.state.accountUserChecksActionRequired = true;
      } else if (this.state.accountUserChecksActionRequired) {
        this.state.accountUserChecksActionRequired = false;
      }

      this.state.accountUserChecksFetched = true;
    } catch (e) {
      logger.error(`${this.logPrefix} Failed to fetch user checks:`, e);
      throw e;
    } finally {
      this.isFetchingUserChecks.value = false;
    }
  };

  public isSettingAccount = ref(false);

  public set = async (
    {
      earlyAdopter,
      accountId,
      accountIdHolder,
    }: {
      earlyAdopter: boolean;
      accountId: string;
      accountIdHolder: string;
    },
    getAccessToken: AccountChannelGetTokenListener,
  ) => {
    if (!sleepService.appIsAsleep.value) {
      /** dont open WS connection during "sleep" */ // TODO do not subscribe on eotc page
      // this.subscribeToTrading(getAccessToken);
      this.subscribeToAccountHistory(getAccessToken);
    }

    if (this.state.accountId === accountId) {
      return;
    }

    this.isSettingAccount.value = true;
    this.state.accountId = accountId;
    this.state.accountIdHolder = accountIdHolder;
    this.state.adopter = earlyAdopter;

    if (!this.state.accountUIId) {
      this.setAccountUIId(accountId);
    }

    launchdarkly.identify(accountIdHolder);
    settingsService.setupSubscriptions();

    const promises = [
      { fn: () => this.fetchHolderUser(), type: 'fetchHolderUser' },
      { fn: () => feesService.fetchAccountFees(), type: 'fetchAccountFees' },
      { fn: () => featureRestrictionsService.get(), type: 'fetchFeatureRestrictions' },
      { fn: () => futuresService.getLeverage(), type: 'fetchLeverage' },
      { fn: () => marketTypeTradingEligibilityService.getMarketTypeTradingEligibility(), type: 'fetchMarketTypeTradingEligibility' },
      { fn: () => subaccountsService.startListPolling(), type: 'fetchSubaccounts' },
    ];

    const callPromises = async (arr: typeof promises) => {
      const results = await Promise.allSettled(arr.map((p) => p.fn()));

      const rejected = results
        .map((result, i) => {
          if (result.status === 'rejected') {
            logger.warn(`${this.logPrefix} Set user partly failed at: ${arr[i]?.type}; retrying later`);

            return arr[i];
          }

          return undefined;
        })
        .filter((p) => p !== undefined) as typeof promises;

      if (rejected.length) {
        await retryService.waitForNextRetryTick();
        await callPromises(rejected);
      }
    };

    await callPromises(promises);

    this.isSettingAccount.value = false;
  };

  public awaitAccountUser = (): Promise<UserModel> => {
    if (this.state.accountUser) {
      return Promise.resolve(this.state.accountUser);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.accountUser,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue);
          }
        },
      );
    });
  };

  public checkIdentity = async () => {
    const [geoInfo, identity] = await Promise.all([
      CryptoInfoRest.GeoInfo.get().catch(() => ({ isoCode: '' })),
      PersonalRest.Identity.get().catch(() => ({ addressOfResidency: { country: '' } })),
    ]);

    return {
      isoCode: geoInfo.isoCode,
      residency: identity?.addressOfResidency?.country ?? '',
    };
  };

  public reset = ({ unsubscribeFromAccountHistory } = { unsubscribeFromAccountHistory: true }) => {
    Object.assign(this.state, getDefaultStateData());
    accountRatioService.reset();
    balanceService.reset();
    featureRestrictionsService.reset();
    futuresService.reset();
    marketTypeTradingEligibilityService.reset();
    ordersService.reset();
    subaccountsService.reset();

    if (unsubscribeFromAccountHistory) {
      this.unsubscribeToAccountHistory();
      Object.assign(this.state, getDefaultStateUnsubscribeMethods());
    }
  };
}

const as = new AccountService();

export default as;
