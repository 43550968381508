import { ref, shallowRef } from 'vue';

import { authService } from '@exchange/libs/auth/service/src';
import ForbiddenJurisdictionModal from '@exchange/libs/jurisdiction/ForbiddenJurisdictionModal.vue';
import { modalService } from '@exchange/libs/modals/src';
import { oAuthRest } from '@exchange/libs/rest-api/personal-api';
import { NotificationKey } from '@exchange/libs/rest-api/personal-api/notification';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { SimpleToast, toastManagerInstance } from '@exchange/libs/toasts/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import type { UserCheckHandler } from './user-check';

const showForbiddenJurisdictionModal = ({ email, residency }: { email: string; residency: string }) => {
  const hasOptedToBeNotified = ref(false);

  modalService.show(
    shallowRef(ForbiddenJurisdictionModal),
    {
      canNotifyMe: !!email,
      hasOptedToBeNotified,
    },
    {
      'contact-support': () => {
        const supportUrl = 'https://support.onetrading.com/requests/new';

        if (capIsNativePlatform()) {
          handleNativeGoTo(supportUrl);
        } else {
          window.location.href = supportUrl;
        }
      },
      logout: async () => {
        try {
          await authService.signOut();
        } catch (e) {
          logger.error(e);
          toastManagerInstance.addToast({
            content: SimpleToast,
            props: {
              variant: 'failed',
              title: 'fundamentals.error.title',
              message: 'fundamentals.error.text',
            },
          });
        }
      },
      'notify-me': async () => {
        try {
          await oAuthRest.Notification.post(NotificationKey.JURISDICTION_OPENING, {
            email,
            residencyCountry: residency,
          });
          toastManagerInstance.addToast({
            content: SimpleToast,
            props: {
              variant: 'success',
              title: 'ui.forbiddenJurisdictionModal.notifyMeSuccess.title',
              message: 'ui.forbiddenJurisdictionModal.notifyMeSuccess.description',
            },
          });

          hasOptedToBeNotified.value = true;
        } catch (e) {
          toastManagerInstance.addToast({
            content: SimpleToast,
            props: {
              variant: 'failed',
              title: 'fundamentals.toasts.failed',
              message: 'fundamentals.error.text',
            },
          });
          logger.error('Failed to notify about forbidden jurisdiction:', e);
        }
      },
    },
    { centered: true },
  );
};

const handleForbiddenJurisdiction: UserCheckHandler<[{ email: string; residency: string }]> = async ({ email, residency }) => {
  const jurisdictions = await PublicRest.Jurisdictions.get().catch(() => ({}));

  if (!jurisdictions[residency]) {
    return {
      actionRequired: false,
    };
  }

  if (jurisdictions[residency].amlRisk === 'prohibited') {
    return {
      actionRequired: true,
      action: () => showForbiddenJurisdictionModal({ email, residency }),
    };
  }

  return {
    actionRequired: false,
  };
};

export default handleForbiddenJurisdiction;
