import { shallowRef } from 'vue';

import UkSelfCertificationModal from '@exchange/libs/account/ui/src/libs/UkSelfCertificationModal.vue';
import UkSelfCertificationNotEligibleModal from '@exchange/libs/account/ui/src/libs/UkSelfCertificationNotEligibleModal.vue';
import { authService } from '@exchange/libs/auth/service/src';
import { modalService } from '@exchange/libs/modals/src';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { SimpleToast, toastManagerInstance } from '@exchange/libs/toasts/src';
import { SubaccountType } from '@exchange/libs/trading-accounts/service/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import type { UserCheckHandler } from './user-check';

export const getPhase = (start: string) => {
  const dayX = new Date(start).getTime();
  const currentDay = new Date().getTime();

  return currentDay < dayX ? 0 : 1;
};

const getUserQuestionnaireInfo = async (questionnaireKey: string) => {
  try {
    const data = await PublicRest.Customer.Questionnaire.get(questionnaireKey);

    return data;
  } catch (e) {
    if ((e as { status: number }).status === 404) {
      return undefined;
    }

    throw e;
  }
};

const showUkSelfCertificationModal = async (phase: number, pid: string, nativeCallback: () => void) => {
  const mKey = modalService.show(
    shallowRef(UkSelfCertificationModal),
    {
      phase,
    },
    {
      close: () => modalService.hide(mKey),
      'go-to-form': async () => {
        const fcaQuestionsUrl = `${process.env.VUE_APP_ACCOUNTS_URL}/self-certification/${pid}`;

        if (capIsNativePlatform()) {
          handleNativeGoTo(fcaQuestionsUrl, nativeCallback);
          modalService.hide(mKey);
        } else {
          window.location.href = fcaQuestionsUrl;
        }
      },
    },
    { centered: true },
  );
};

const showUkSelfCertificationNotEligibleModal = async (nativeCallback: () => void) => {
  const mKey = modalService.show(
    shallowRef(UkSelfCertificationNotEligibleModal),
    {},
    {
      'contact-support': () => {
        const supportUrl = 'https://support.onetrading.com/requests/new';

        if (capIsNativePlatform()) {
          handleNativeGoTo(supportUrl, nativeCallback);
          modalService.hide(mKey);
        } else {
          window.location.href = supportUrl;
        }
      },
      logout: async () => {
        try {
          await authService.signOut();
        } catch (e) {
          logger.error(e);
          toastManagerInstance.addToast({
            content: SimpleToast,
            props: {
              variant: 'failed',
              title: 'fundamentals.error.title',
              message: 'fundamentals.error.text',
            },
          });
        }
      },
    },
    { centered: true },
  );
};

const handleUKCustomer: UserCheckHandler<[{ residency: string; isoCode: string }, string, SubaccountType | undefined, () => void]> = async (
  { residency, isoCode },
  pid,
  mainAccountType,
  nativeCallback,
) => {
  logger.info(`Residency: ${residency}; geolocation: ${isoCode}`);

  if (residency !== 'GB' || mainAccountType === SubaccountType.FUTURES) {
    return {
      actionRequired: false,
    };
  }

  const data = await getUserQuestionnaireInfo('uk-fca');
  const phase = getPhase(launchdarkly.flags['uk-fca'].value.start);

  if (!data) {
    return {
      actionRequired: true,
      action: () => showUkSelfCertificationModal(phase, pid, nativeCallback),
    };
  }

  const [firstAnswer] = data.answers || [];

  if (phase === 1 && firstAnswer?.answer === 'none') {
    return {
      actionRequired: true,
      action: () => showUkSelfCertificationNotEligibleModal(nativeCallback),
    };
  }

  return {
    actionRequired: false,
  };
};

export default handleUKCustomer;
